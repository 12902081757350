import React from 'react'
import Table from '../components/Table'


const Dashboard = () => {
  return (
    <div className='overflow-x-hidden w-full'>
      <Table/>
    </div>
  )
}

export default Dashboard
