import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import toast from "react-hot-toast";
import axiosInstance from "../axiosInstance/axiosInstance";
import Skeleton from "react-loading-skeleton"; // Import Skeleton

const StudentAdmissionTable = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);

  const paginatedStudents = students.slice(start, end);
  // console.log(paginatedJobs);

  const handleNext = () => {
    if (end < students.length) {
      setStart(end);
      setEnd(end + 10);
    }
  };

  const handlePrevious = () => {
    if (start > 0) {
      setEnd(start);
      setStart(start - 10);
    }
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const { data } = await axiosInstance.get("/admission/get");
      setStudents(data.students);
    } catch (error) {
      toast.error("Data not fetched");
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mx-3">
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        {students.length > 0 ? (
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-blue-900 text-white uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">S.No</th>
                <th className="py-3 px-6 text-left">Student Name</th>
                <th className="py-3 px-6 text-left">Course</th>
                <th className="py-3 px-6 text-left">Contact No</th>
                <th className="py-3 px-6 text-left">Batch Timing</th>
                <th className="py-3 px-6 text-left">Admission Date</th>
                <th className="py-3 px-6">View Details</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {loading // Check loading state
                ? Array.from({ length: 5 }).map(
                    (
                      _,
                      index // Create 5 skeleton rows
                    ) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td className="py-3 px-6">
                          <Skeleton width={50} />
                        </td>
                        <td className="py-3 px-6">
                          <Skeleton width={200} />
                        </td>
                        <td className="py-3 px-6">
                          <Skeleton width={150} />
                        </td>
                        <td className="py-3 px-6">
                          <Skeleton width={100} />
                        </td>
                        <td className="py-3 px-6">
                          <Skeleton width={100} />
                        </td>
                        <td className="py-3 px-6">
                          <Skeleton width={30} />
                        </td>
                      </tr>
                    )
                  )
                : paginatedStudents.map((student, index) => (
                    <tr
                      key={student.id}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-6 text-left whitespace-nowrap font-bold">
                        {index + 1 + start}
                      </td>
                      <td className="py-3 px-6 text-left font-bold capitalize">
                        {student.studentName}
                      </td>
                      <td className="py-3 px-6 text-left font-bold">
                        {student.courseName}
                      </td>
                      <td className="py-3 px-6 text-left font-bold">
                        {student.number}
                      </td>
                      <td className="py-3 px-6 text-left font-bold">
                        {student.batchTiming}
                      </td>
                      <td className="py-3 px-6 text-left font-bold">
                        {student.admissionDate}
                      </td>
                      <td className="py-3 px-6">
                        <Link
                          to={`/student-admission/${student._id}`}
                          className="focus:outline-none flex justify-center"
                        >
                          <FaEye className="text-xl text-center" />
                        </Link>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        ) : (
          <h1 className="text-3xl text-center overflow-hidden text-red-300 font-bold">
            No Data found
          </h1>
        )}
      </div>
      <div className="flex fixed bottom-0 p-2 w-full justify-evenly">
        <button onClick={handlePrevious} disabled={start === 0}>
          Previous
        </button>
        <button disabled className="bg-slate-500" >
          Total Students: {students.length}
        </button>
        <button onClick={handleNext} disabled={end >= students.length}>
          Next
        </button>
      </div>
    </div>
  );
};

export default StudentAdmissionTable;
