import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axiosInstance from "../axiosInstance/axiosInstance";

export default function Card() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  // console.log(title);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/form/add", {
        title: title,
        url: url,
      });
      console.log(response);
      setTitle("");
      setUrl("");
      toast.success("Job is added");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="bg-blue-800 flex items-center justify-center  text-white font-bold text-center text-2xl py-4">
        <h1 className="w-fit">Post Jobs</h1>
      </div>
      <div className="p-5 shadow-md">
        <div className="flex flex-col">
          <label
            id="title"
            className="relative left-4 z-10 w-fit bg-white font-semibold"
          >
            Add Title
          </label>
          <input
            placeholder="Job Title"
            type="text"
            name="title"
            className="input"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            id="title"
            className="relative top-[18px] left-4 z-10 w-fit bg-white font-semibold"
          >
            Add URL
          </label>
          <input
            placeholder="Job URL"
            type="text"
            name="text"
            className="input"
            required
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <button onClick={handleSubmit}>Add</button>
        </div>
      </div>
    </div>
  );
}

// export default Card;
