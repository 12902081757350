import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import axiosInstance from "../axiosInstance/axiosInstance";
import { FaEye } from "react-icons/fa";
import ViewInstallMentsModel from "../components/ViewInstallMentsModel";

const FeeDetails = () => {
  const [feeDetails, setFeeDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInstallments, setSelectedInstallments] = useState(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);

  const paginatedFeeDetails = feeDetails.slice(start, end);
  // console.log(paginatedJobs);

  const handleNext = () => {
    if (end < feeDetails.length) {
      setStart(end);
      setEnd(end + 10);
    }
  };

  const handlePrevious = () => {
    if (start > 0) {
      setEnd(start);
      setStart(start - 10);
    }
  };

  const handleOpen = (installments) => {
    setSelectedInstallments(installments); // Set the selected installments
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedInstallments(null); // Clear selected installments on close
  };

  // Fetch fee
  const fetchFee = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const { data } = await axiosInstance.get("/fee");
      // console.log(data);
      setFeeDetails(data);
    } catch (error) {
      toast.error("Data not fetched");
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Calculate total paid and remaining fee
  const totalPaidFee = feeDetails.reduce((acc, item) => acc + item.paidFee, 0);
  const totalRemainingFee = feeDetails.reduce(
    (acc, item) => acc + item.remainingFee,
    0
  );

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };

  useEffect(() => {
    fetchFee();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <div className="bg-blue-800 flex items-center justify-evenly font-bold text-center text-2xl py-4">
          <h1 className="w-fit text-white">Fee Details</h1>
        </div>
        <section className="overflow-y-scroll">
          <div className="mx-3">
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              {feeDetails.length > 0 ? (
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="bg-blue-900 text-white uppercase text-sm leading-normal">
                      <th className="py-3 px-6 text-left">S.No</th>
                      <th className="py-3 px-6 text-left">Name</th>
                      <th className="py-3 px-6 text-left">Number</th>
                      <th className="py-3 px-6 text-center">Course Fee</th>
                      <th className="py-3 px-6 text-center">Paid Amount</th>
                      <th className="py-3 px-6 text-center">
                        Remaining Amount
                      </th>
                      <th className="py-3 px-6 text-left">Installments</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                    {loading
                      ? Array.from({ length: 5 }).map((_, index) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="py-3 px-6">
                              <Skeleton width={50} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={200} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={150} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={100} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={100} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={30} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={30} />
                            </td>
                          </tr>
                        ))
                      : paginatedFeeDetails.map((s, index) => (
                          <tr
                            key={index}
                            className="border-b border-gray-200 hover:bg-gray-100"
                          >
                            <td className="py-3 px-6 text-left whitespace-nowrap font-bold">
                              {index + 1 + start}
                            </td>
                            <td className="py-3 px-6 text-left font-bold capitalize">
                              {s.studentName}
                            </td>
                            <td className="py-3 px-6 text-left font-bold">
                              {s.number}
                            </td>
                            <td className="py-3 px-6 text-center font-bold">
                              {s.totalFee}
                            </td>
                            <td className="py-3 px-6 text-center font-bold">
                              <span className="bg-green-100 rounded-2xl px-2">
                                {s.paidFee}
                              </span>
                            </td>
                            <td className="py-3 px-6 text-center font-bold">
                              <span className="bg-red-100 rounded-2xl px-2">
                                {s.remainingFee}
                              </span>
                            </td>
                            <td className="py-3 px-6 flex gap-10 text-center font-bold">
                              {s.installments.length}
                              <FaEye
                                onClick={() => handleOpen(s.installments)} // Pass the installments for the selected fee detail
                                className="text-center text-xl cursor-pointer"
                              />
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              ) : (
                <h1 className="text-3xl text-center overflow-hidden text-red-300 font-bold">
                  No Data found
                </h1>
              )}
            </div>
          </div>
        </section>
        <div className="flex fixed bottom-0 p-2 w-full justify-evenly">
          <button onClick={handlePrevious} disabled={start === 0}>
            Previous
          </button>
          <button disabled className="bg-slate-500 w-fit px-2">
            Total Paid Fee: {formatNumber(totalPaidFee)}/-
          </button>
          <button disabled className="bg-slate-500 w-fit px-2">
            Total Remaining Fee: {formatNumber(totalRemainingFee)}/-
          </button>
          <button onClick={handleNext} disabled={end >= feeDetails.length}>
            Next
          </button>
        </div>
      </div>
      <ViewInstallMentsModel
        handleClose={handleClose}
        installments={selectedInstallments} // Pass the selected installments to the modal
        isOpen={isOpen}
      />
    </>
  );
};

export default FeeDetails;
