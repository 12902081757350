import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "axios";

const ContentSection = ({ control, register, index, remove }) => {
  const {
    fields: pointFields,
    append: appendPoint,
    remove: removePoint,
  } = useFieldArray({
    control,
    name: `blog_content.${index}.points`,
  });

  return (
    <div className="mb-4 p-4 border rounded-md bg-blue-100">
      <div className="flex justify-between items-center">
        <h3 className="text-xl underline font-semibold">
          Content Section {index + 1}
        </h3>
        <button
          type="button"
          onClick={() => remove(index)}
          className="bg-red-500 text-white px-3 py-1 rounded-md"
        >
          Delete
        </button>
      </div>
      <div className="flex justify-between w-full gap-10">
        <div className="mb-4 w-1/3">
          <label className="block text-gray-700 text-xl">Heading</label>
          <input
            placeholder="Content Heading"
            {...register(`blog_content.${index}.heading`)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="mb-4 w-1/3">
          <label className="block text-gray-700 text-xl">Description</label>
          <textarea
            placeholder="Content Description"
            rows={1}
            {...register(`blog_content.${index}.description`)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="mb-4 w-1/3">
          <label className="block text-gray-700 text-xl">Poster Image</label>
          <input
            type="file"
            accept="image/*"
            {...register(`blog_content.${index}.poster`)}
            className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
      </div>
      <div className="mb-4">
        <button
          type="button"
          onClick={() => appendPoint({ heading: "", description: "" })}
          className="bg-green-500 text-white px-3 py-2 rounded-md"
        >
          Add Point
        </button>
      </div>
      {pointFields.map((point, pointIndex) => (
        <div
          key={point.id}
          className="mb-4 pl-4 border-l-2 bg-blue-300 p-5 rounded-xl"
        >
          <div className="flex justify-between items-center">
            <h4 className="text-xl underline font-medium">
              Point {pointIndex + 1}
            </h4>
            <button
              type="button"
              onClick={() => removePoint(pointIndex)}
              className="bg-red-500 text-white px-3 py-1 rounded-md"
            >
              Delete
            </button>
          </div>
          <div className="flex justify-between w-full gap-10">
            <div className="mb-4 w-1/2">
              <label className="block text-xl text-gray-700">Heading</label>
              <input
                {...register(
                  `blog_content.${index}.points.${pointIndex}.heading`
                )}
                placeholder="Points Heading"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4 w-1/2">
              <label className="block text-gray-700 text-xl">Description</label>
              <textarea
                rows={1}
                placeholder="Points Description"
                {...register(
                  `blog_content.${index}.points.${pointIndex}.description`
                )}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const BlogForm = () => {
  const { register, handleSubmit, control, reset, watch } = useForm();
  const {
    fields: contentFields,
    append: appendContent,
    remove: removeContent,
  } = useFieldArray({
    control,
    name: "blog_content",
  });

  const [blogImage, setBlogImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("blog_heading", data.blog_heading);
    formData.append("blog_description", data.blog_description);
    if (blogImage) formData.append("blog_image", blogImage);

    data.blog_content.forEach((content, index) => {
      formData.append(`blog_content[${index}][heading]`, content.heading);
      formData.append(
        `blog_content[${index}][description]`,
        content.description
      );
      if (content.poster?.[0]) {
        formData.append(`blog_content[${index}][poster]`, content.poster[0]);
      }
      content.points.forEach((point, pointIndex) => {
        formData.append(
          `blog_content[${index}][points][${pointIndex}][heading]`,
          point.heading
        );
        formData.append(
          `blog_content[${index}][points][${pointIndex}][description]`,
          point.description
        );
      });
    });

    try {
      const response = await axios.post("/api/blogs/add-blog", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handlePreview = () => {
    setShowPreview(!true);
  };

  const previewData = watch();

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-4 mx-auto bg-white shadow-md rounded-lg"
      >
        <div className="flex justify-between w-full gap-10">
          <div className="mb-4 w-1/3">
            <label className="block text-gray-700 text-xl font-bold">
              Blog Title
            </label>
            <input
              placeholder="Blog Title"
              {...register("blog_heading", { required: true })}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4 w-1/3">
            <label className="block text-gray-700 text-xl font-bold">
              Blog Description
            </label>
            <textarea
              rows={1}
              placeholder="Blog Description"
              {...register("blog_description", { required: true })}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4 w-1/3">
            <label className="block text-gray-700 text-xl font-bold">
              Thumbnail Image
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setBlogImage(e.target.files[0])}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        <div className="mb-4">
          <button
            type="button"
            onClick={() =>
              appendContent({
                heading: "",
                description: "",
                points: [{ heading: "", description: "" }],
                poster: "",
              })
            }
            className="bg-blue-500 text-white px-3 py-2 rounded-md"
          >
            Add Content
          </button>
        </div>
        {contentFields.map((content, index) => (
          <ContentSection
            key={content.id}
            control={control}
            register={register}
            index={index}
            remove={removeContent}
          />
        ))}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={handlePreview}
            className="bg-yellow-500 text-white px-4 py-2 rounded-md"
          >
            Preview
          </button>
          <button
            type="submit"
            className="bg-indigo-500 text-white px-4 py-2 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>

      {showPreview && (
        <div className="mt-8 p-4 border rounded-md bg-gray-100">
          <h2 className="text-2xl font-bold mb-4">Blog Preview</h2>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border p-2">Section</th>
                <th className="border p-2">Heading</th>
                <th className="border p-2">Description</th>
                <th className="border p-2">Poster</th>
                <th className="border p-2">Points</th>
              </tr>
            </thead>
            <tbody>
              {previewData.blog_content?.map((content, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className="border p-2" rowSpan={content.points.length + 1}>
                      Content Section {index + 1}
                    </td>
                    <td className="border p-2">{content.heading}</td>
                    <td className="border p-2">{content.description}</td>
                    <td className="border p-2">
                      {content.poster?.[0]?.name || "No image"}
                    </td>
                    <td className="border p-2"></td>
                  </tr>
                  {content.points.map((point, pointIndex) => (
                    <tr key={pointIndex}>
                      <td className="border p-2">{point.heading}</td>
                      <td className="border p-2">{point.description}</td>
                      <td className="border p-2"></td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BlogForm;
