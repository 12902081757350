import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance/axiosInstance";
import toast from "react-hot-toast";

const ProjectEnquiry = () => {
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState([]);

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const { data } = await axiosInstance.get("/service_enquiry/get");

      console.log(data.data);

      setService(data.data);
    } catch (error) {
      toast.error("Data not fetched");
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col w-full h-[100vh]">
      <div className="bg-blue-800 flex items-center justify-center  text-white font-bold text-center text-2xl py-4">
        <h1 className="w-fit">Project Enquiry</h1>
      </div>
      <section className="overflow-y-scroll">
        <div className="mx-3 mt-4">
          <div className="overflow-x-auto bg-white shadow-md rounded-lg">
            {service.length > 0 ? (
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-blue-900 text-white uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">S.No</th>
                    <th className="py-3 px-6 text-left">Name</th>
                    <th className="py-3 px-6 text-left">Email</th>
                    <th className="py-3 px-6 text-left">Contact No</th>
                    <th className="py-3 px-6 text-left">Service</th>
                    <th className="py-3 px-6">Date</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {loading // Check loading state
                    ? Array.from({ length: 5 }).map(
                        (
                          _,
                          index // Create 5 skeleton rows
                        ) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="py-3 px-6">
                              <Skeleton width={50} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={200} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={150} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={100} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={100} />
                            </td>
                            <td className="py-3 px-6">
                              <Skeleton width={30} />
                            </td>
                          </tr>
                        )
                      )
                    : service.map((s, index) => (
                        <tr
                          key={s.id}
                          className="border-b border-gray-200 hover:bg-gray-100"
                        >
                          <td className="py-3 px-6 text-left whitespace-nowrap font-bold">
                            {index + 1}
                          </td>
                          <td className="py-3 px-6 text-left font-bold capitalize">
                            {s.name}
                          </td>
                          <td className="py-3 px-6 text-left font-bold">
                            {s.email}
                          </td>
                          <td className="py-3 px-6 text-left font-bold">
                            {s.phone}
                          </td>
                          <td className="py-3 px-6 text-left font-bold">
                            {s.service}
                          </td>
                          <td className="py-3 px-6 text-left font-bold">
                            {formatDate(s.createdAt)}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            ) : (
              <h1 className="text-3xl text-center overflow-hidden text-red-300 font-bold">
                No Data found
              </h1>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectEnquiry;
